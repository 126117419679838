@import "../../typography.css";
@import "../../design_tokens.css";
@import "../../mixins.css";
@import "../../colors.css";

.component {
  top: 0;
  left: 0;
  right: 0;
  z-index: 6;
  -webkit-transform: translate3d(0%, 0, 0);

  header {
    @mixin transitionTwo color, background-color;
    color: inherit;
    height: $mobileHeaderHeight;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-flow: row wrap;

    @media (--desktop) {
      height: $desktopFullHeaderHeight;
    }
  }

  .topPortion {
    /* Better server-side rendering to prevent header "jumping" on render */
    display: flex;
    width: 100%;
    max-height: $desktopMinifiedHeaderHeight;
  }

  &.transparentOnLoad {
    position: absolute;
  }
  &.solidOnLoad {
    position: relative;
  }
  &.whiteBackground {
    header {
      @mixin background_primary;
    }
  }
  &.sticky {
    position: sticky;
    header {
      @mixin background_primary;
    }
  }

  .navButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;

    @media (--desktop) {
      display: none;
    }
  }

  .navButtonContainerWrapper {
    display: flex;
    margin-right: auto; /* Needed to push logo to true center. */
  }

  .logoContainer {
    display: flex;
    align-items: center;
    @media (--smartphone-and-tablet){
      height: 100%;
      @mixin text_primary;
      font-family: "Spezia Medium";
      font-weight: 400;
    }
  }

  .navigationContainer{
    width: 100%;
    align-content: center;

    & > a {
      padding: 0 $spacingSm;
    }
  }

  .staticLinkContainer, .guideShopLink {
    @mixin hide_on_smartphone_and_tablet;
    @media (--desktop) {
      flex: 1;
      display: flex;
      align-items: center;
      padding: 0 $spacingMd;
    }
  }

  .guideShopLink {
    padding: 0;
    margin-left: $spacingSm;
  }

  .logoIcon {
    display: flex;
    svg {
      height: 30px;

      g {
        @mixin fill_dark;
        @mixin transition fill;
      }
      width: 150px;
        margin-top: 0;

      @media (--desktop) {
        width: 198px;
        margin-top: $spacingNudge;
      }
    }
  }

  .accountContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  .accountContainerWrapper {
    margin-left: auto; /* Needed to push logo to true center. */
    display: flex;
    align-items: center;

    @media (--smartphone-and-tablet) {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      height: 100%;
    }
    & > div {
        button > a {
          @mixin transition color;
          &:after {
            @mixin transition border-top;
          }
        }
    }
  }

  /* IE11 fix to get full nav on screen */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .accountContainerWrapper {
      margin-left: 0px;
    }
  }

  .cartBtn {
    appearance: none;
    margin: 0;
    display: flex;
    vertical-align: middle;
    position: relative;
    padding: 3px $spacingSm 0 $spacingXs;
    height: $mobileHeaderHeight;
    line-height: $mobileHeaderHeight;

    &:hover {
      cursor: pointer;
    }

    @media (--desktop) {
      padding: 0 $spacingSm;
      line-height: 60px;
      height: 60px;
    }
  }

  .cartLink {
    padding: 0;
    margin: 0;
    color: inherit;
    background: transparent;
    border: none;
  }

  .searchIcon {
    width: 1em;
  }

  .searchIcon svg {
    display: inline-block;
    @mixin fill_dark;
    @mixin transition fill;

    @media (--desktop) {
      display: none;
    }
  }

  .searchContainer {
    display: inline-block;
    @mixin metaDescriptionText;
    color: inherit;

    @media(--desktop) {
      height: 100%;
      vertical-align: top;
      padding-right: $spacingSm;
      input, form, div {
        /* display: inline-block; */
      }
    }
    svg {
      @mixin transition fill;
    }
    input {
      @mixin transitionTwo color, border-color;
    }
  }

  .cartQtyNumber {
    @mixin typePrimary;
    @mixin text_primary;
    @mixin transition color;
    line-height: unset;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    text-align: center;
    font-size: 14px;

    @media(--smartphone-and-tablet){
      top: 2px;
      left: -4px;
      font-size: 12px;
    }
  }

  .cartIcon {
    display: flex;
    align-items: center;

    svg {
      @mixin transition color;
      margin-top: -$spacingXs;
    }
  }

  .desktopNavigationContainer {
    width: 100%;
    flex-grow: 1;
    @mixin background_primary;
    position: fixed;
    left: 0;
    text-align: left;
    top: $desktopFullHeaderHeight;
    display: block;
    opacity: 0;
    overflow: hidden;
    height: 0;
    @mixin transitionTwo height, opacity;

    &.open {
      display: block;
      opacity: 1;
      height: 454px; /* images are 438px, +16px for padding */
    }
  }

  .desktopNav {
    display: none;

    &.active {
      display: block;
    }
  }

  .navigationWrapper {
    display: flex;
    justify-content: center;
    opacity: 1;
    &.hidden {
      opacity: 0;
    }
    & > a {
      @mixin transition color;
    }
  }

  /*
    The navigation includes top-level links which make
    it impossible to navigate to the subnav on assistive
    devices and touch screens. So, hide the subnav in
    these instances.
  */
  body[data-keyboard="true"], body[data-touch="true"] {
    .desktopNav {
      &.active {
        display: none;
      }
    }
  }

  /* Light styles for transparent header */
  &.light {
    header {
      @mixin text_white;
    }

    .searchContainer {
      svg {
        @mixin fill_white;
      }
      input {
        @mixin text_white;
        border-color: white;
      }
    }

    .logoIcon {
      svg {
        g {
          @mixin fill_white;
        }
      }
    }

    .cartIcon {
      svg {
        @mixin icon_color_white;
      }
    }

    .staticLinkContainer > a,
    .navigationWrapper > a {
      @mixin text_white;
    }

    .staticPageLink {
      @mixin text_white;
    }

    .cartQtyNumber {
      @mixin text_white;
    }

    .accountContainerWrapper {
      & > div {
        button > a {
          /* target the account/sign in dropdown */
          @mixin text_white;
          /* target the dropdown caret */
          &:after {
            border-top: 5px solid $white1;
          }
        }
      }
    }
  }
  /*
    Minified styles to update the header when scrolling
  */
  &.minified {
    header {
      height: $desktopMinifiedHeaderHeight;
      box-shadow: 0 4px 4px 0 rgba(158, 158, 158, 0.1);
    }
    .logoContainer {
      @media (--large-desktop) {
        display: auto;
      }
      @media (--desktop) {
        display: none;
      }
    }
    .logoIcon {
      padding-left: 1.2vw;
      display: flex;
      svg {
        height: 27px;
        width: 12vw;
        min-width: 120px;
        max-width: 140px;
        margin-top: 0;
      }
    }
    .desktopNavigationContainer {
      top: $desktopMinifiedHeaderHeight;
    }

    .navigationContainer {
      position: absolute;
      width: 75%;
      left: 2%;
      @media (--small-desktop) {
        width: 85%;
      }
      .navigationWrapper {
        height: $desktopMinifiedHeaderHeight;
        align-items: center;
        @media (--desktop) {
          justify-content: flex-start;
        }
        @media (--large-desktop) {
          justify-content: flex;
        }
        & > a {
          padding: $spacingSm $spacingNudge;
          @media (--large-desktop) {
            @mixin navigationText;
          }
        }
      }
    }
  }
}

.findYourFitLink {
  @mixin subNavigationText;
  margin: $spacingXs 0 $spacingXs $spacingMd;
  padding: $spacingXs $spacingSm $spacingXs $spacingSm;
  color: $white1;
  background-color: $black;
  border-radius: $spacingNudge;
}

.loadingBg {
  @mixin background_tertiary;
  position: absolute;
  bottom: -1px;
  width: 100%;
}

.loadingBar {
  height: 2px;
  @mixin background_loading;
}

/*
  The navigation includes top-level links which make
  it impossible to navigate to the subnav on assistive
  devices and touch screens. So, hide the subnav in
  these instances.
*/
body[data-keyboard="true"], body[data-touch="true"] {
  .desktopNavigationContainer {
    opacity: 0 !important;
    height: 0px !important;
  }
}

.curtain {
  @mixin transition opacity;
  background-color: $black;
  opacity: 0;
  width: 100%;
  height: 0;
  left: 0;
  top: 100%;
  position: absolute;

  &.open {
    opacity: 0.55;
    height: 100vh;
  }
}
